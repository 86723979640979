import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Swiper } from "swiper/react";
import { Autoplay } from "swiper/modules";

function StyledSwiper({ children }) {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={7}
        // slidesPerGroup={1}
        loop={true}
        autoplay={{
          delay: 1500,
          // disableOnInteraction: false,
        }}
        // loopFillGroupWithBlank={true}
        // navigation={{
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // }}
        modules={[Autoplay]}
      >
        {children}
      </Swiper>
    </>
  );
}

export default StyledSwiper;
