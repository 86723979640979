import React, { useState, useEffect, useMemo } from "react";
import { responsiveFontSizes } from "@mui/material/styles";
import { ThemeProvider, Backdrop } from "@mui/material";
import { InfinitySpin } from "react-loader-spinner";
import CssBaseline from "@mui/material/CssBaseline";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import HeroSection from "./pages/HeroSection";
import { useNetwork } from "wagmi";
import { useAccount } from "wagmi";
import NetworkSwitch from "./NetworkSwitch";
import { createCustomTheme } from "./utils/createCustomTheme";

function App() {
  const { address } = useAccount();
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const { chain, chains } = useNetwork();
  // eslint-disable-next-line
  const [mode, setMode] = useState("light");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (address && chain && chain?.id !== chains[0].id) {
      setOpenNetworkSwitch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chain, address]);

  const themeClient = useMemo(() => {
    let theme = createCustomTheme(mode);
    theme = responsiveFontSizes(theme);
    return theme;
  }, [mode]);
  return (
    <>
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      <ThemeProvider theme={themeClient}>
        <CssBaseline enableColorScheme />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          limit={3}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={mode}
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <InfinitySpin width="200" color={`#fff`} />
        </Backdrop>
        <Header />
      </ThemeProvider>
      <HeroSection setloading={setLoader} />
    </>
  );
}

export default App;
