import React from "react";
import { Box, Container, useMediaQuery } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";

import { SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import StyledSwiper from "./SwiperSlider";

const FeaturedSlider = ({ nftImages }) => {
  const matches = useMediaQuery("(max-width:960px)");

  return (
    <>
      <Box position="relative" width="100%">
        <Container maxWidth="xl">
          <StyledSwiper>
            {nftImages.length > 0 ? (
              nftImages.map((item, index) => {
                return (
                  <SwiperSlide key={index} style={{ borderRadius: "10%" }}>
                    <Box display="flex" justifyContent="center">
                      <img
                        src={item}
                        width={matches ? "90%" : "70%"}
                        style={{ borderRadius: "16px" }}
                        alt=""
                      />
                    </Box>
                  </SwiperSlide>
                );
              })
            ) : (
              <SwiperSlide style={{ borderRadius: "10%" }}>
                <Box display="flex" justifyContent="center" height="350px">
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={true}
                  />
                </Box>
              </SwiperSlide>
            )}
          </StyledSwiper>
        </Container>
      </Box>
    </>
  );
};

export default FeaturedSlider;
