import React from "react";
import { Contract } from "@ethersproject/contracts";
import preSaleAbi from "./preSaleAbi.json";
import nftAbi from "./nftAbi.json";
import { ethers } from "ethers";
import { MaxUint256 } from "@ethersproject/constants";
import { BigNumber } from "@ethersproject/bignumber";
import { NFTPresale, SharbaeNFT } from "./environment";

export const provider = new ethers.providers.JsonRpcProvider(
  "https://www.shibrpc.com"
);
export const voidAccount = new ethers.VoidSigner(
  "0x97d7b2051c10831A9ced3F7Ed7e32DBdDE92fFDE",
  provider
);
function useContract(address, ABI, signer) {
  return React.useMemo(() => {
    if (signer) {
      return new Contract(address, ABI, signer);
    } else {
      return new Contract(address, ABI, voidAccount);
    }
  }, [address, ABI, signer]);
}

export function PresaleNFTContract(signer) {
  return useContract(NFTPresale, preSaleAbi, signer);
}
export function NFTContract(signer) {
  return useContract(SharbaeNFT, nftAbi, signer);
}

export function calculateGasMargin(value) {
  return +(
    (value * BigNumber.from(10000).add(BigNumber.from(1000))) /
    BigNumber.from(10000)
  ).toFixed(0);
}
export const gasEstimationPayable = async (account, fn, data, amount) => {
  if (account) {
    const estimateGas = await fn(...data, MaxUint256).catch(() => {
      return fn(...data, { value: amount.toString() });
    });
    return calculateGasMargin(estimateGas);
  }
};
