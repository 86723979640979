import React, { useEffect, useState } from "react";
import { Container, Box } from "@mui/material";
import { logo } from "./SmallComponents/Images";
import { useAccount } from "wagmi";
import { StyledButton } from "./SmallComponents/AppComponents";

import ConnectionModal from "./Modals/ConnectionModal";
import DisConnectModal from "./Modals/DisConnectModal";

export default function Header() {
  const [connectModal, setConnectModal] = useState(false);
  const [disconnectModal, setDisconnectModal] = useState(false);
  const [renderModal, setrenderModal] = useState(false);

  const { address, isConnected } = useAccount();

  const toggleConnectModal = () => () => {
    setConnectModal(!connectModal);
  };

  const toggleDisConnectModal = () => () => {
    setDisconnectModal(!disconnectModal);
  };

  useEffect(() => {
    if (isConnected) {
      setTimeout(() => {
        setDisconnectModal(false);
        setrenderModal(true);
      }, 4000);
    } else {
      setConnectModal(false);
      setrenderModal(false);
    }
  }, [isConnected]);

  return (
    <>
      {renderModal ? (
        <DisConnectModal
          toggleDisConnectModal={toggleDisConnectModal}
          disconnectModal={disconnectModal}
        />
      ) : (
        <ConnectionModal
          toggleConnectModal={toggleConnectModal}
          connectModal={connectModal}
        />
      )}
      <Box
        sx={{
          background: "transparent",
        }}
        height="92px"
        width="100%"
        py={1}
      >
        <Container maxWidth="lg">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <img width="110px" src={logo} alt="" />

            {isConnected ? (
              <StyledButton onClick={toggleDisConnectModal()}>
                {address.slice(0, 6) + "..." + address.slice(-4)}
              </StyledButton>
            ) : (
              <StyledButton onClick={toggleConnectModal()}>
                Connect Wallet
              </StyledButton>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
}
