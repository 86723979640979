import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  Stack,
} from "@mui/material";
import { StyledButton } from "../components/SmallComponents/AppComponents";
import FeaturedSlider from "./FeaturedSlider";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useAccount, useSigner } from "wagmi";
import { toast } from "react-toastify";
import {
  NFTContract,
  PresaleNFTContract,
  gasEstimationPayable,
} from "../ConnectivityAssets/hooks";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 18,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    border: "2px solid #40C7F1",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#B054A0" : "#308fe8",
  },
}));
const nftImage = (id) => {
  return `https://ivory-uncertain-chipmunk-701.mypinata.cloud/ipfs/QmUSLm9FNd4V4reHHzs2ThfsiUtwuQTxhXZXUdbQknBaKC/images/${id}.png`;
};
const nftMeta = (id) => {
  return `https://ivory-uncertain-chipmunk-701.mypinata.cloud/ipfs/QmYSyKyywaM72UJv5PYQ1obdv64HwrCCVPu4LB5M1RG5TS/${id}.json`;
};

function HeroSection({ setloading }) {
  const matches = useMediaQuery("(max-width:960px)");
  const { address } = useAccount();
  const { data: signer } = useSigner();
  const [counter, setCounter] = useState(1);
  const [nftImages, setNFTImages] = useState([]);
  const [totalNFT, setTotalNFT] = useState(0);
  const [nftMetaData, setNFTMetaData] = useState([]);
  const SharbaeNFTContract = NFTContract();
  const PresaleContract = PresaleNFTContract(signer);

  const decrement = () => {
    if (counter === 1) {
      setCounter(1);
    } else {
      setCounter(counter - 1);
    }
  };
  const init = async () => {
    let total = await PresaleContract.totalMintedNfts();

    setTotalNFT(+total);
  };

  init();

  const setNFTS = async () => {
    let newId = await SharbaeNFTContract.totalSupply();
    let imgArr = [];
    let metaDataArr = [];
    for (let i = 0; i < counter; i++) {
      let img = nftImage(+newId + i + 1);
      imgArr.push(img);
      let metaData = nftMeta(+newId + i + 1);
      metaDataArr.push(metaData);
    }
    setNFTImages(imgArr);
    setNFTMetaData(metaDataArr);
  };
  useEffect(() => {
    setNFTS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);
  const setMinting = async () => {
    if (!address) {
      toast.error("Error! Please connect your wallet.");
    } else {
      try {
        setloading(true);
        let price = await PresaleContract.nftPrice();
        price = +price * counter;
        let fn = PresaleContract.estimateGas.buy;
        let data = [counter, address, nftMetaData];

        let tx = await PresaleContract.buy(...data, {
          value: price.toString(),
          gasLimit: await gasEstimationPayable(address, fn, data, price),
        });
        await tx.wait();
        toast.success("Minting Successful!");
        setCounter(1);
        await setNFTS();
        setloading(false);
      } catch (err) {
        console.log(err);
        if (err?.data?.message) {
          toast.error(err?.data?.message);
        } else if (err?.reason) {
          toast.error(err?.reason);
        } else {
          toast.error(err?.message);
        }
        setloading(false);
      }
    }
  };
  return (
    <>
      <Box pb={5}>
        <Container maxWidth="lg">
          <Grid container spacing={3} mt={matches ? 4 : 12}>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: matches ? "40px" : "56px",
                  fontWeight: "400",
                  color: "#B054A0",
                  fontFamily: "Passion One",
                  lineHeight: matches ? "38px" : "60px",
                }}
              >
                <span style={{ color: "#40C7F1" }}>Sharbae's </span> Gold
                Digging Adventure
              </Typography>
              <Typography
                sx={{
                  my: 3,
                  fontSize: matches ? "14px" : "18px",
                  fontWeight: "400",
                  color: "#000",
                  fontFamily: "Open Sans",
                }}
              >
                Can a man have a meaningful relationship with a cartoon dog?
                There's only one way to find out. Mint some Sharbae and begin
                your gold digging adventure. Sharbae is your ticket to monthly
                treasure hunting competitions, real $ETH prizes, and life time
                of concern and disappointment from your friends, family, and
                co-workers. 2,000 will be airdropped to various Shibarium
                project token holders. 2,000 more will be minted here. <br />
                Are you feeling... furry?
              </Typography>

              <StyledButton width="160px">Whitepaper</StyledButton>
            </Grid>
            <Grid item xs={12} md={6} mt={matches ? 6 : 0}>
              <FeaturedSlider nftImages={nftImages} />
              <Box
                mt={3}
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
              >
                <Box display="flex" alignItems="center">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      background: "#B054A0",
                      border: "2px solid #40C7F1",
                      color: "#fff",
                      "&:hover": {
                        background: "transparent",
                        color: "#000",
                        cursor: "pointer",
                        border: "2px solid #B054A0",
                      },
                    }}
                    onClick={decrement}
                  >
                    <RemoveIcon />
                  </Box>
                  <Typography mx={3} fontSize="30px" fontFamily="Passion One">
                    {counter}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      background: "#B054A0",
                      border: "2px solid #40C7F1",
                      color: "#fff",
                      "&:hover": {
                        background: "transparent",
                        color: "#000",
                        border: "2px solid #B054A0",
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => setCounter(counter + 1)}
                  >
                    <AddIcon />
                  </Box>
                </Box>
                <StyledButton width="90px" onClick={setMinting}>
                  MINT
                </StyledButton>
              </Box>
              <Box mt={4}>
                <Box fontWeight={700} fontSize={"18px"}>
                  Total Minted:
                </Box>
                <Stack
                  spacing={2}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    {totalNFT}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    4000
                  </Typography>
                </Stack>
                <BorderLinearProgress
                  variant="determinate"
                  value={totalNFT / 40}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default HeroSection;
